<template>
  <div class="container">
    <!-- 导航 -->
    <van-nav-bar left-arrow title="会议详情" @click-left="onClickBackHandle"/>

    <div class="info-loading" v-if="isLoading">
      <van-loading size="24px" 
        text-color="#000000" 
        color="#1DAF69">{{$t('seeting.loading')}}</van-loading>
    </div>
    
    <!-- 表单 -->
    <template v-else>
    <van-cell-group class="group">
      <van-cell class="multi-row" :value="detail.title" />
    </van-cell-group>

    <van-cell-group class="group" v-if="isAppointment">
      <van-cell class="multi-row">
          <div class="time">
            <div class="block">
            <div>{{ detail.beginTime | formatTimeFilter('HH:mm') }}</div>
            <div>{{ detail.beginTime | formatTimeFilter('YYYY年MM月DD日') }}</div>
          </div>
          <van-divider class="divider">{{ detail.beginTime | formatDurationFilter(detail.endTime, '小时','分钟')}}</van-divider>
          <div class="block">
            <div>{{ detail.endTime | formatTimeFilter('HH:mm') }}</div>
            <div>{{ detail.endTime | formatTimeFilter('YYYY年MM月DD日') }}</div>
          </div>
         </div>
      </van-cell>
    </van-cell-group>

     <van-cell-group class="group" v-else>
      <van-cell title="会议开始时间" :value="detail.enterTime | formatTimeFilter('YYYY年MM月DD日 HH:mm')" />
      <van-cell title="会议结束时间" :value="detail.exitTime | formatTimeFilter('YYYY年MM月DD日 HH:mm')" />
    </van-cell-group>

    <van-cell-group class="group" v-if="isAppointment">
      <van-cell title="会议号" :value="detail.conferenceNo | formatCodeFilter" />
    </van-cell-group>

    <van-cell-group class="group" v-else>
      <van-cell title="会议号" :value="detail.conferenceNo | formatCodeFilter" />
      <van-cell title="发起人" class="overflow-cell" :value="detail.ownerName" />
    </van-cell-group>

    <van-cell-group class="group" v-if="isAppointment">
      <van-cell title="会议密码" :value="detail.passwordEnable && detail.password ? detail.password : '无'" />
      <van-cell title="主持人密码" :value="detail.ownerPassword || '无'" />
    </van-cell-group>

    <!-- 业务组件 加入会议按钮--->
    <join-button v-if="joinButtonText" class="button-join" :userName="userName" :conferenceNo="detail.conferenceNo" :active="true">{{ joinButtonText }}</join-button>

    <van-button type="primary" class="button del" :class="{'top-margin': !joinButtonText }" v-if="!isAppointment" @click="onHistoryMeetingDelete(detail.rtcRoomNo)">删除会议</van-button>

    <div class="action-area" v-if="isAppointment">
      <div class="action-btn" @click="onMeetingInvite">
        <my-icon fontSize="48" iconName="icon-yaoqing_48_hei" />
        <div>会议邀请</div>
      </div>
      <div class="action-btn" @click="onMeetingModify(detail.id)" v-if="detail.status === 0">
        <my-icon fontSize="48" iconName="icon-yicheng_48_hei" />
        <div>修改信息</div>
      </div>
      <div class="action-btn" @click="onOrderMeetingDelete(detail.id)">
        <my-icon fontSize="48" iconName="icon-shanchu_48_hei" />
        <div>删除会议</div>
      </div>
    </div>
    </template>
    
    
    <!--- 会议邀请弹窗 --->
    <share v-model="showSharePop" @close="showSharePop = false"></share>
  </div>
</template>

<script>
import '@/utils/filter'
import JoinButton from '@/components/button/JoinButton'
import Share from '@/components/Share/index'

import { getLocalUserInfo } from '@/utils/auth'

export default {
  data() {
    return {
       isAppointment: false, //true 预约 false 历史
       detail:  {},
       userName: '',
       id:'',
       showSharePop: false,
       isLoading: true
    }
  },

  computed: {
    joinButtonText() {
      if (this.isAppointment) {
        return this.$t('seeting.goMeet')
      } else if(!this.isAppointment && this.detail.type === 1 && this.detail.status != 50) {
        return this.$t('seeting.joinAgain')
      }
    }
  },

  components: {
    JoinButton,
    Share
  },
  
  mounted () {
    this.isAppointment = this.$route.query?.meetingType == '0' ? true : false

    this.id = this.$route.query?.id
    
    const userInfo =  getLocalUserInfo()
    this.userName = userInfo.realName


    //---------------- SDK 获取会议信息 -------------------------
    sdk.meetingManagement.getMeetingInfo(this.id)
    .then(
      data => {
          this.isLoading = false

          this.detail = data
          this.detail.rtcRoomNo = this.$route.query?.rtcRoomNo

          if (this.$route.query.hasOwnProperty('enterTime')) {
            this.detail.enterTime = this.$route.query?.enterTime
          }
          if (this.$route.query.hasOwnProperty('exitTime')) {
            this.detail.exitTime = this.$route.query?.exitTime
          }  
      }
    ).catch(
      error => {
        console.error(error)  
      }
    )
  },

  methods: {
    onClickBackHandle() {
      const meetingType = this.$route.query?.meetingType
      this.$router.push({ name: 'home', query: { active: 1, tab: meetingType }})
    },

    onMeetingInvite() {
      this.showSharePop = true
    },

    onMeetingModify() {
      this.$router.push({ name: 'appointment' ,params: { meetingId: this.$route.query.id } })
    },

    confirmDialog(cb) {
      this.$dialog.confirm({
        title: this.$t('seeting.deleteMeet'),
        confirmButtonText: this.$t('seeting.deleteMeet'),
        confirmButtonColor:'#FF4D4F',
        cancelButtonText: this.$t('seeting.thinkAgain'),
        message: this.$t('seeting.noDelete')
      })
      .then(() => cb && cb())
      .catch(()=> {})
    },

    async onOrderMeetingDelete(id) {
      //会议正在进行中，先进行结束会议的提示
      const detail = await sdk.meetingManagement.getMeetingInfo(this.id)
      this.detail = detail || {}
      
      if (this.detail.status === 10) {
        this.$eventBus.$emit('end-meeting-handle', {
          data: this.detail,
          msg: '是否结束当前正在进行中的会议'
        }, this.deleteOrderMeetingHandle, [id])
      } else {
        this.deleteOrderMeetingHandle(id)
      }   
    },

    deleteOrderMeetingHandle(id) {
      const self = this
      this.confirmDialog(() => {
          const deleteMeetingFunc = function() {
            sdk.meetingManagement.deleteMeeting(id)
            .then(
              res => self.onClickBackHandle()
            ).catch(
              error => {
                console.error(error)
                self.$eventBus.$emit('request-error', error)
              }
            ) 
          }
          deleteMeetingFunc()
      }) 
    },

    onHistoryMeetingDelete(id) {
      const self = this
      this.confirmDialog(() => {
         const deleteMeetingFunc = function() {
           sdk.meetingManagement.deleteHistoryMeeting(id)
            .then(
              res => self.onClickBackHandle()
            ).catch(
              error => {
                console.error(error)
                self.$eventBus.$emit('request-error', error)
              }
            ) 
         }
         deleteMeetingFunc()
      })
    }

  },


  // beforeRouteEnter(to, from, next) {
    
  //   const exceptionExitMsgObj = localStorage.getItem('exceptionExitMsg');
  
  //   if(exceptionExitMsgObj && from.name == 'meeting') {
  //     console.log('joinButton ', to, from);
  //     window.location.reload()
  //   } else {
  //     next({})
  //   }

  // }
  
}
</script>

<style scoped lang="less">
.flex_center(@justify:center) { 
    display: flex;
    align-items: center;
    justify-content: @justify;
}
.text_overflow() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.container {
   background: #FAFAFA;
   width: 100%;
   height: 100%;
   padding-bottom: 50px;

   .info-loading {
     width: 280px;
     height: 88px;
     line-height: 88px;
     background: #FFFFFF;
     box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
     border-radius: 44px;
     border: 1px solid rgba(0, 0, 0, 0.1);
     text-align: center;
     position: absolute;
     top: 144px;
     left: calc(50% - 140px);
   }

   .group {
     margin-top: 16px;
   }

   .group .van-cell {
     height: 104px;
     padding: 30px;
     font-size: 32px;
   }
   .group .van-cell__value {
     color:#333;
   }
   .multi-row {
     height: auto !important;
     overflow: auto !important;
   }

   .time {
     .flex_center();
     .block {
       width: 190px;
       height: 108px;
       text-align: center;
     }
     .block div:nth-child(1) {
       height: 67px;
       font-size: 48px;
       font-weight: 600;
       color: #333333;
       line-height: 67px;
     }
     .block div:nth-child(2) {
       margin-top: 8px;
       font-size: 24px;
       line-height: 33px;
     }
   }

   .divider {
      color: #333333; 
      border-color: rgba(0, 0, 0, 0.2); 
      padding: 0 8px;
      font-size: 24px;
      margin-top: -10px;
      width: 252px;
   }

   .button {
     width: 622px;
     height: 88px;
     border-radius: 10px;
     font-size: 32px;
     font-weight: 500;
     border:none;
     display: block;
     margin:0 auto;
     margin-bottom: 32px;
   }
   .button-join {
     margin-top: 80px;
     margin-bottom: 32px;
     text-align: center;
   }
   /deep/ .button-join .button{
     width: 622px;
   }
   .top-margin {
     margin-top: 80px;
   }


   .button.del {
     background: #fff;
     color:#FF4D4F;
     border: 1px solid #E5E5E5;
   }

   .action-area {
     .flex_center();
     text-align: center;
     width: 622px;
     height: 146px;
     background: #FFFFFF;
     border-radius: 16px;
     border: 1px solid #E5E5E5;
     margin:0 auto;

     .action-btn {
       width: 33.3%;
       height: 100%;
       .flex_center();
       flex-direction: column;
     }
     .action-btn div {
        height: 33px;
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        margin-top: 16px;
     }
   }
}

::v-deep .van-nav-bar__title {
  font-size: 32px;
  font-weight: 400;
  color: #333333;
}
::v-deep .van-nav-bar .van-icon {
  color:#333;
}

::v-deep .van-divider--content-center::before {
   margin-right: 4px;
}
::v-deep .van-divider--content-center::after {
   margin-left: 4px;
}
::v-deep .overflow-cell .van-cell__value{
  .text_overflow();
}
::v-deep .van-dialog__confirm, .van-dialog__confirm:active {
  color:#FF4D4F;
}
</style>